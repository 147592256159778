import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import "../../styles/odooServices.scss"
import News from "../../components/News"
import OdooTopSection from "../../components/OdooTopSection"
import WhyChooseVisiomateforD365 from "../../components/WhyChooseVisiomateforD365"
import Dynamics365OptimizationImageWithTextSection from "../../components/Dynamics365OptimizationImageWithTextSection"
import OdooSupportSimplified from "../../components/OdooSupportSimplified"
import Wethoughtyoumighthavequestions from "../../components/Wethoughtyoumighthavequestions"
import RecentSuccessStories from "../../components/RecentSuccessStories"

import img1 from "../../images/Odoo/ORR-01-01.png"
import img2 from "../../images/Odoo/Aamaal-01.png"
const Index = ({ data }) => {
    return (
        <div className="Support">
            <Layout pageTitle={"Visiomate - Support for Odoo Operations"}
            descrip = {"Get expert support for your Odoo ERP system. Resolve issues, optimize performance, and ensure smooth operations. Contact us today!"}>
                <OdooTopSection
                    headerData={{
                        title:
                            "Elevate Your Odoo Journey with Comprehensive, Reliable, and Unmatched Support Services Unlocking Excellence",
                    }}
                    data={data}
                />
                <Dynamics365OptimizationImageWithTextSection
                    content={{
                        heading:
                            "Unlocking Odoo Excellence: Harnessing the Strength of Expert Support",
                        description:
                            "An adept Odoo support team delivers swift issue resolution, expert customization guidance, and ensures peak system performance. They actively contribute to user training, tailor the platform to specific needs, and prioritize security and compliance.",
                        d2:
                            "Offering strategic insights, the team assists in future-proofing the system, serving as a proactive partner for seamless operations aligned with organizational objectives.",
                    }}

                    data={data} />
                <WhyChooseVisiomateforD365 data={{
                    title: 'Why Choose Visiomate for Odoo Support Team?', headings: [
                        { heading: 'In-Depth Knowledge', description: "The Visiomate team demonstrates profound expertise and mastery of the Odoo platform, effortlessly navigating complexities and delivering effective solutions. Their knowledge encompasses best practices, customizations, and seamless system integrations." },
                        { heading: 'Proactive Issue Resolution', description: "Our team is proactive in anticipating and identifying potential issues before they impact operations. Through proactive monitoring, swift diagnostics, and efficient problem-solving, we ensure minimal downtime and maintain optimal system health." },
                        { heading: 'Customization Expertise', description: "With extensive experience in tailoring Odoo to meet unique business requirements, our team ensures strategic implementation of customizations, thereby enhancing system flexibility and scalability." },
                        { heading: 'Cost-Effective Solutions', description: "While expertise does come with a cost, selecting Visiomate ensures a cost-effective approach. We minimize downtime, prevent critical issues, and optimize the overall efficiency of your Odoo environment, maximizing the value of your investment." },
                    ]
                }}
                    dataFormImages={data} />
                <RecentSuccessStories data={{
                    title: 'Recent Success Stories ', Text: [
                        { description: "Visimoate Helped ORR with multiple projects, Odoo customization & day to day support was one of the major projects.  ", image: img1 },
                        { description: "Odoo support Services for modules: Point of sales, Accounting, Inventory, Sales, Purchase and Human Resource.", image: img2 },

                    ]
                }}
                    dataFormImages={data} />
                <OdooSupportSimplified />
                <Wethoughtyoumighthavequestions
                    data={[
                        {
                            question: "What support services are available for Odoo?",
                            answer:
                                "Visiomate offers various support services to assist users with their software needs. These services may include technical support, bug fixes, software updates, consulting, training, and access to documentation and resources.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question: "How can I access support services for Odoo?",
                            answer:
                                "Users can access support services for Odoo through several channels:",
                            answer1:
                                "- Odoo Helpdesk: Users can submit support tickets through the Odoo Helpdesk portal.",
                            answer2:
                                "- Odoo Community Forums: Users can seek assistance from the Odoo community forums, where they can ask questions, share knowledge, and collaborate with other users and developers.",
                            answer3:
                                "- Odoo Partners such as Visiomate: Users can engage with Odoo partners, consultants, or developers who offer support services tailored to their specific needs.",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "What types of issues can be addressed through support services for Odoo?",
                            answer:
                                "Support services for Odoo can address a wide range of issues, including technical issues, software bugs, configuration problems, and usage questions. Users can also seek assistance with customization, integration, and optimization of their Odoo instances.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "What is included in Odoo's technical support services?",
                            answer:
                                "Odoo's technical support services typically include assistance with troubleshooting technical issues, diagnosing software bugs, providing software updates and patches, and resolving configuration or setup problems. Users may also receive guidance on best practices for using Odoo effectively.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "Are there different levels of support available for Odoo?",
                            answer:
                                "Yes, Odoo may offer different levels of support depending on the user's subscription plan or service agreement. Higher-tier support plans may include additional features such as faster response times, dedicated support representatives, priority bug fixes, and proactive monitoring.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "How can I escalate issues or request additional support for Odoo?",
                            answer:
                                "Users can escalate issues or request additional support for Odoo by contacting their designated support representative, submitting a support ticket through the Odoo Helpdesk portal, or reaching out to Odoo partners or consultants for assistance.",
                            answer1: "",
                            answer2: "",
                            answer3: "",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "What are the benefits of investing in support services for Odoo?",
                            answer:
                                "Investing in support services for Odoo offers several benefits, including:",
                            answer1:
                                "- Timely resolution of technical issues and software bugs, minimizing downtime and disruption to business operations.",
                            answer2:
                                "- Access to expertise and guidance from Odoo experts, consultants, or partners to optimize Odoo implementations and achieve business objectives.",
                            answer3:
                                "- Peace of mind knowing that users have access to resources, documentation, and assistance to address any challenges or questions that arise while using Odoo.",
                            answer4: "",
                            answer5: "",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                        {
                            question:
                                "How can I choose the right support services for my organization's needs?",
                            answer:
                                "When choosing support services for Odoo, consider factors such as:",
                            answer1:
                                "- Level of Support: Determine the level of support you need based on your organization's size, complexity, and requirements.",
                            answer2:
                                "- Service Level Agreements (SLAs): Review SLAs to understand response times, resolution targets, and availability of support resources.",
                            answer3:
                                "- Expertise and Experience: Evaluate the expertise, experience, and certifications of support providers, including Odoo partners, consultants, or developers.",
                            answer4:
                                "- Cost and Budget: Consider the cost of support services and how they fit within your organization's budget and resource constraints.",
                            answer5:
                                "- Feedback and Recommendations: Seek feedback and recommendations from other organizations, peers, or industry experts who have experience with the support services you are considering.",
                            answer6: "",
                            answer7: "",
                            answer8: "",
                            answer9: "",
                        },
                    ]}
                    title="We thought you might have questions"
                />
                <News isAbout={true} career={true} />
            </Layout>
        </div>
    )
}

export default Index
export const query = graphql`
  query OdoooSupportImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odooimg: file(relativePath: { eq: "Odoobg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages1: file(relativePath: { eq: "Odoo/ORR-01-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }  
    RSSImages2: file(relativePath: { eq: "Odoo/Aamaal-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SecondDivImg: file(
      relativePath: {
        eq: "hands-holding-puzzle-business-problem-solving-concept 1.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
